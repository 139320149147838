/*app.js*/
import { useEffect } from 'react';
import './App.css';
import Screen from './components/screen';

function App() {
  useEffect(() => {
    const redirectIfLargeScreen = () => {
      if (window.innerWidth >= 768) {
        window.location.href = 'https://mfkn.org/';
      }
    };

    redirectIfLargeScreen(); // Check on initial load

    // Check on window resize
    window.addEventListener('resize', redirectIfLargeScreen);

    return () => {
      window.removeEventListener('resize', redirectIfLargeScreen);
    };
  }, []);

  return (
    <Screen/>
  );
}

export default App;
