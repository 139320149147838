export function matrixRain() {
    var c = document.getElementById("matrixRain");
    var ctx = c.getContext("2d");

    //canvas full screen
    c.height = window.innerHeight;
    c.width = window.innerWidth;

    //english char
    var english = "10⋗ΣΠΣ木৲XƆ⋏⋌とぐぃムニウゾ";

    //converting the string into an array of single characters
    english = english.split("");

    var font_size = 6;
    var columns = (c.width/font_size); //number of columns for the rain

    //an array of drops - one per column
    var drops = [];

    //x below is the x coordinate
    for(var x = 0; x < columns; x++) 
        drops[x] = Math.floor(Math.random() * c.height); // random starting position
     
    //drawing the characters
    function draw()
    {
        //Black BG for the canvas
        //translucent BG to show trail
        ctx.fillStyle = "rgba(0, 0, 0, 0.05)";
        ctx.fillRect(0, 0, c.width, c.height);

        ctx.fillStyle = "#E21330"; //green text #0F0 //red #cf102b
        ctx.font = font_size + "px arial";
    
        //looping over drops
        for(var i = 0; i < drops.length; i++)
        {
            //a random character to print
            var text = english[Math.floor(Math.random()*english.length)];
            //x = i*font_size, y = value of drops[i]*font_size
            ctx.fillText(text, i*font_size, drops[i]*font_size);
    
            //sending the drop back to the top randomly after it has crossed the screen
            //adding a randomness to the reset to make the drops scattered on the Y axis
            if(drops[i]*font_size > c.height && Math.random() > 0.975)
            drops[i] = 0;
    
            //incrementing Y coordinate
            drops[i]++;
        }
    }

    draw();

    setInterval(draw, 60);
}